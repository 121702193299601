<template>
  <form class="file-upload" method="post" enctype="multipart/form-data">
    <div class="file-upload">
      <div v-if="isLoading">
        <i class="icon icon-loader"></i>
      </div>
      <div v-else>
        <input type="file" :accept="$props.accepts" @change="uploadLogo" />
        <!-- the inline style here fixes the clickable area bug -->
        <div class="button secondary" style="z-index: 1">{{ $props.label }}</div>
      </div>
    </div>
  </form>
</template>

<script>
//import { fileApi } from "@/http/http.config";
import { restApi } from "@/http/http.config";
import { encodeWithParam } from "@/filters";
import { convertBufferToContent } from "@/util/BufferContentConverter";

export default {
  name: "FileUpload",
  props: {
    label: {
      type: String,
      require: false,
      default: "",
    },
    data: {
      type: Object,
      require: true,
      default: ()=> {}
    },
    clientId: {
      type: String,
      require: false,
      default: "",
    },
    filename: {
      type: String,
      require: false,
      default: "",
    },
    public: {
      type: Boolean,
      require: false,
      default: false,
    },
    accepts: {
      required: false,
      default: () => {
        ["image/*", ".css"];
      },
    },
    uploadedFor: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed:{
    fileData() {
      return this.$props.data
    }
  },
  methods: {
    async uploadLogo(e) {
      this.isLoading = true;
      let formData = new FormData();
      const fileName = this.$props.filename == "whitelabelling" ? e.target.files[0].name : this.$props.filename;
      const uploadedBy = this.$store.getters.getUserID;
  

      
      formData.append("clientLocationID",this.fileData.locationID );
      // uploaded for allows us to use the spoof feature of the endpoint and upload a file that a partner will be able to see
      formData.append("userID", this.$props.uploadedFor.length > 0 ? this.$props.uploadedFor : uploadedBy);
      formData.append("filename", fileName);
      formData.append("acl", "public");
      formData.append("file", e.target.files[0]);
      formData.append("orginalName",e.target.files[0].name);
      formData.append("clientID",process.env.VUE_APP_CLIENT_ID),
      formData.append("clientLocationPartnerID",this.fileData.locationPartnerID),
      formData.append("documentTemplateID",this.fileData.documentTemplateID),
      formData.append("signature",this.fileData.signature)
      //to do
      if (this.$props.clientLocation) {
        formData.append("insertClientLocation", true);
      }
       restApi.post('/employee/uploadFile', formData).then((data) => {
        if(!data.data.success){
          this.$emit("uploadRespHandler", {success: false, message: data.data.message});
          return;
        }
        restApi.post('/employee/getUploadedFile',encodeWithParam({fileID: data.data.rows[0].fileID})).then((data1) => {
          this.isLoading = false;
          let fileData={
            fileID: data.data.rows[0].fileID,
            signedDocumentID: data.data.rows[0].signedDocumentID,
            filename: data.data.rows[0].filename,
            fileURL:convertBufferToContent(data1.data.bufferInHex,data1.data.contentType)
          }
        this.$emit("change", fileData);
        })
      })
      
      
    },
  },
};
</script>
