<template>
  <base-modal :id="$props.id" :key="$props.modalKey" :class="$props.partnerPageInsuranceMicroModal ? 'insurance-preview-model-wrapper is-open' : 'insurance-preview-model-wrapper'">
    <span class="close-button" @click="cancel"><i class="icon-x"></i></span>
    <div v-if="$props.showPreview" class="preview-insurance-modal-content" :key="$props.modalKey">
      <div class="module">
        <h4>Validate Auto Insurance</h4>
        <p>Please verify the image is a valid insurance card and the expiration date in the submitted image matches the expiration date below.</p>
        <div v-if="$props.data.insuranceImageURL !== null" class="viewer-wrapper">
          <div v-if="$props.data.isPdf">
            <vue-pdf-embed :source="$props.data.insuranceImageURL" />
          </div>
          <div v-if="!$props.data.isPdf">
            <viewer ref="viewer" :options="options" :images="images" class="viewer" @inited="initialized">
              <template>
                <img :src="$props.data.insuranceImageURL" alt="Image Preview" />
              </template>
            </viewer>
          </div>
          <a class="button secondary" @click="removeImage">Remove Image</a>
        </div>
        <div v-else class="well upload-block">
          <file-upload label="Choose Image to Upload" :filename="'Insurance'" :accepts="arrayOfformats" :uploaded-for="candidateUserId" @change="updateInsuranceImage" @uploadRespHandler="fileUploadRespHandler" :data="this.$props.data"></file-upload>
        </div>
        <label v-if="fileUploadError" style="color: red;">Error : Please upload the document again</label>
        <label v-if="validFileError" style="color: red;">Please upload image with valid file format.</label>
        <div style="padding: 10px 0px">
          <label>Auto Insurance Expiration</label>
          <Datepicker :disabled-dates="disabledDates" v-model="$props.data.insuranceExpiry"></Datepicker>
          <label>*Insurance should be current and active for the next 45 days.</label>
        </div>
        <save-cancel emit-cancel save-label="Validate" cancel-label="Invalidate" :saveEnabled="enableButtons" :cancelEnabled="enableButtons" @onSave="submit" @onCancel="invalidateInsurance" />
      </div>
    </div>
    <div v-else class="loader"><vue-simple-spinner /></div>
  </base-modal>
</template>
<style>
.insurance-preview-model-wrapper .upload-block {
  margin-bottom: 15px;
}
.insurance-preview-model-wrapper .date-pick-expiry {
  margin-bottom: 15px;
}
</style>
<script>
import { BaseModal, SaveCancel, FileUpload } from "@/components";
import { DateTime } from "luxon";
import micromodal from "micromodal";
import Datepicker from "vuejs-datepicker";
import { FunnelStatus } from "@/util/funnelProgress";
import { restApi } from "@/http/http.config";
import { encodeWithParam } from "@/util/Base64Encoding";
import { component as Viewer } from "v-viewer";
import VueSimpleSpinner from "vue-simple-spinner";
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";
import { mapGetters } from "vuex";


export default {
  components: {
    FileUpload,
    SaveCancel,
    BaseModal,
    Viewer,
    Datepicker,
    VueSimpleSpinner,
    VuePdfEmbed,
  },
  props: {
    showPreview: {
      type: Boolean,
      required: true,
      default: false,
    },
    data: {
      type: Object,
      required: false,
      default: () => {},
    },
    id: {
      type: String,
      required: false,
      default: "modal-insurance-expiration",
    },
    modalKey: {
      type: Number,
      required: false,
      default: 0,
    },
    partnerPageInsuranceMicroModal: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      options: {},
      imageUrl: "",
      arrayOfformats: 
      ["jpg", "png", "jpeg", "gif", "pdf"]
      ,
      validFileError: false,
      fileUploadError: false,
      disabledDates: {
        to: new Date(Date.now() + 45 * 24 * 60 * 60 * 1000),
      },
      tempInsuranceExpiry : null,
      isValidateEnabled: false,
      insuranceExpiry: null,
      showInsuranceImage: false,
      tempInsuranceFileID: null
    };
  },
  watch: {
    data: {
      deep: true,
      immediate: true,
      handler: function(newVal) {
        // console.log(newVal, "invalidateInsurance")
        this.insuranceExpiry = newVal.insuranceExpiry;
        // this.insuranceExpiry=DateTime.fromISO(newVal.insuranceExpiry, {zone: 'America/Los_Angeles'}).toFormat("dd MMM yyyy")
        if(!this.tempInsuranceExpiry || this.tempInsuranceExpiry === 'Invalid DateTime') {
          this.tempInsuranceExpiry = newVal.insuranceExpiry;
        }
        if(!this.tempInsuranceFileID) {
          this.tempInsuranceFileID = newVal.insuranceFileId;
        }
        this.isValidateEnabled = (Boolean(this.insuranceExpiry) && Boolean(newVal.insuranceImageURL) && this.insuranceExpiry !== 'Invalid DateTime');
      }
    },
    insuranceExpiry: {
      handler: function(newVal) {
        this.$props.data.insuranceExpiry = this.insuranceExpiry;
        // let dt=new Date(this.insuranceExpiry)
        // this.$props.data.insuranceExpiry=DateTime.fromISO(this.insuranceExpiry, {zone: 'America/Los_Angeles'}).toFormat("dd MMM yyyy")
        // this.$props.data.insuranceExpiry = `${dt.getFullYear()}-${(dt.getMonth()+1).toString().padStart(2,'0')}-${dt.getDate().toString().padStart(2,'0')}`
        this.isValidateEnabled = (Boolean(this.$props.data.insuranceImageURL) && Boolean(newVal) && this.insuranceExpiry !== 'Invalid DateTime');
      }
    },

    isFlyoutShowing: {
      handler: function(newVal) {
        if(!newVal) {
          this.tempInsuranceExpiry =  null;
          this.tempInsuranceFileID =  null;
          this.isValidateEnabled =  false;
          this.insuranceExpiry =  null;
          this.showInsuranceImage = false;
          this.fileUploadError = false;
        } 
      }
    }
  },
  computed: {
    ...mapGetters(['isFlyoutShowing']),
    locationID() {
      return this.$props.data.locationID;
    },
    images() {
      return this.imagePreview ? [this.imagePreview] : [];
    },
    imagePreview() {
      return this.$props.data.insuranceImageURL;
    },
    enableButtons(){
      return this.$props.data.insuranceImageURL? true : false;
    },
    candidateUserId() {
      return this.$props.data.partnerUserID;
    },
    expirationDate() {
      return this.$props.data.insuranceExpiry;
      // return this.$props.data.insuranceExpiry;
    },
  },

  methods: {

    
    candidateProgressObject(status) {
      
      return {
        assignedOS: this.$props.data.specialist,
        partnerID: this.$props.data.partnerID,
        stepStatus: status,
      };
    },
    // invalidateInsurance() {
    //   let obj = this.candidateProgressObject(FunnelStatus.INSURANCE_IMAGE_INVALID);
    //   this.updateCandidateProgress(obj).then(() => {
    //     this.$toast.error("Insurance Invalidated");
    //     let customObj = {
    //       phone: this.$props.data.phone,
    //       text: encodeURIComponent(
    //         `Spark Driver app: We cannot accept the proof of auto insurance you uploaded. Acceptable proof of insurance is a clear image that includes your name, expiration date, and vehicle. You can log in to your Spark Driver profile to upload new proof of insurance here:https://bit.ly/3pAkw07`
    //       ),
    //       partnerID: this.$props.data.partnerID,
    //       userID: this.$props.data.userID,
    //       name: 'insurance_rejection' 
    //     };
    //     restApi.post("/onboard/sendSMS", encodeWithParam(customObj));
    //     this.cancel();
    //   });
    // },
    async invalidateInsurance() {
      let obj = this.candidateProgressObject(FunnelStatus.INSURANCE_IMAGE_INVALID);
      this.updateCandidateProgress(obj).then(async()=> {
        await restApi.put('/partner/updateInsuranceInvalidated', encodeWithParam({ partnerID: this.$props.data.partnerID, insuranceExpiry: this.tempInsuranceExpiry, fileId: this.tempInsuranceFileID}));
        this.$toast.error("Insurance Invalidated");
        let customObj = {
          phone: this.$props.data.phone,
          text: encodeURIComponent(`Spark Driver app: We cannot accept the proof of auto insurance you uploaded. Acceptable proof of insurance is a clear image that includes your name, expiration date, and vehicle. Insurance must be valid for the next 45 days, at a minimum. You can log in to your Spark Driver profile to upload new proof of insurance here:  https://my-ca.ddiwork.com/`),
          partnerID: this.$props.data.partnerID,
          userID: this.$props.data.userID,
        };
        this.insuranceExpiry = this.tempInsuranceExpiry;
        this.$props.data.insuranceExpiry = this.tempInsuranceExpiry;
        await restApi.post('/onboard/sendSMS',encodeWithParam(customObj))
        this.cancel()
        this.$emit("onInvalidate")
      })
    },
    async updateCandidateProgress(candidateProgress) {
      return await restApi.post("/onboard/saveCandidateStatus", encodeWithParam(candidateProgress));
    },
    validateInsurance() {
      this.valid = !this.valid;
      return this.valid;
    },
    updateDate(value) {
      this.expirationDate = value;
    },
    submit() {
      this.tempInsuranceExpiry = this.formatDate(this.$props.data.insuranceExpiry);
      this.tempInsuranceFileID = this.$props.data.insuranceFileId;
      this.$emit("onSave", { expirationDate: this.$props.data.insuranceExpiry });
    },
    cancel() {
      this.fileUploadError = false;
      micromodal.close("modal-insurance-expiration");
      this.$emit("onCancel");
    },
    formatDate(date) {
      if (typeof date == "string" || !date) {
        return date;
      } else if(typeof date == "object"){
        return DateTime.fromISO(date.toISOString()).toFormat("dd MMM yyyy");
      }
      return date;
    },
    parseDate(dateString, format) {
      return DateTime.fromFormat(dateString, format).toJSDate();
    },
    initialized(viewer) {
      this.$viewer = viewer;
    },
    removeImage() {
      this.$emit("onRemove");
    },
    isPastDate(date) {
      return DateTime.fromJSDate(date) < DateTime.utc();
    },
    fileUploadRespHandler(data){
      if(!data.success) this.fileUploadError = true;
    },
    updateInsuranceImage(data) {

      let acceptedFormat = ["jpg", "png", "jpeg", "gif", "pdf"];
      if (data.filename) {
        console.log(data.filename, "name of file")
        let nameOfFile = data.filename;
        let fileFormat = nameOfFile?.substring(nameOfFile.lastIndexOf(".")+1);
         

        let isValidformat = acceptedFormat.some((o) => fileFormat?.includes(o));
        if(isValidformat){
          this.validFileError = false;
          this.$emit("imageChanged", data);
        }else{
          this.validFileError = true;
        }
        
      }

      
    },
  },
};
</script>
<style scoped>
.checkbox-custom1 {
  display: flex;
  margin-bottom: 24px;
  min-height: 18px;
  padding: 6px;
}
.close-button {
  position: relative;
  font-size: 25px;
  left: 100%;
  bottom: 35px;
  cursor: pointer;
}
.loader {
  height: 200px;
  display: grid;
  align-items: center;
}
</style>
